body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 780px) and (orientation: portrait) {
  html {
    transform: rotate(90deg);
    transform-origin: right top;
    width: 100dvh;
    height: 100dvw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    right: 0;
  }
}

/* noto-sans-400normal - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
          local('Noto Sans Regular '),
          local('Noto Sans-Regular'),
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-regular.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-400italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
          local('Noto Sans Regular italic'),
          local('Noto Sans-Regularitalic'),
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-italic.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-700normal - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
          local('Noto Sans Bold '),
          local('Noto Sans-Bold'),
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-700.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
          local('Noto Sans Bold italic'),
          local('Noto Sans-Bolditalic'),
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('./shared/ui/fonts/noto-sans-v9-latin_cyrillic_cyrillic-ext_latin-ext-700italic.woff') format('woff'); /* Modern Browsers */
}

* {
  font-family: 'Noto sans', sans-serif;
}

a,
a:focus,
a:active,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}
